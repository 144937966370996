import React, { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import "../styles/InspectionsPage.css";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";

const NewInspection = () => {
  // All useState hooks should come first
  const [templates, setTemplates] = useState([]);
  const [yards, setYards] = useState([]);
  const [gms, setGms] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [sections, setSections] = useState([]);
  const [responses, setResponses] = useState({});
  const [userId, setUserId] = useState(null);
  const [baseData, setBaseData] = useState({
    date: "",
    yard_id: "",
    gm_id: "",
    lastVisitDate: "",
    lastVisitRating: "",
    currentVisitRating: "",
    template_id: "",
  });
  const [currentRating, setCurrentRating] = useState(0);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [modalContent, setModalContent] = useState(""); // State for modal content
  const navigate = useNavigate();

  // Modal functions
  const openModal = (description) => {
    setModalContent(description);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    fetchTemplates();
    fetchYards();
    fetchGms();
    fetchUserId();
  }, []);

  useEffect(() => {
    console.log("Effect: currentRating changed to", currentRating);
  }, [currentRating]);

  useEffect(() => {
    if (baseData.yard_id && baseData.template_id) {
      fetchLastInspection(baseData.yard_id, baseData.template_id);
    }
  }, [baseData.yard_id, baseData.template_id]);

  useEffect(() => {
    console.log(
      "Effect: baseData.currentVisitRating changed to",
      baseData.currentVisitRating
    );
  }, [baseData.currentVisitRating]);

  const fetchUserId = async () => {
    const {
      data: { user },
      error,
    } = await supabase.auth.getUser();
    if (error) {
      console.error("Error fetching user:", error);
      setError("Error fetching user information.");
      return;
    }
    if (user) {
      setUserId(user.id);
    }
  };

  const fetchTemplates = async () => {
    try {
      let { data, error } = await supabase
        .from("inspection_templates")
        .select("id, name");
      if (error) throw error;
      setTemplates(data);
    } catch (error) {
      setError("Failed to fetch templates.");
      console.error(error);
    }
  };

  const fetchYards = async () => {
    try {
      let { data, error } = await supabase.from("yards").select("id, name");
      if (error) throw error;
      setYards(data);
    } catch (error) {
      setError("Failed to fetch yards.");
      console.error(error);
    }
  };

  const fetchGms = async () => {
    try {
      let { data, error } = await supabase
        .from("employees")
        .select("id, name")
        .eq("position", "GM");
      if (error) throw error;
      setGms(data);
    } catch (error) {
      setError("Failed to fetch GMs.");
      console.error(error);
    }
  };

  const fetchLastInspection = async (yardId, templateId) => {
    if (!yardId || !templateId) {
      console.log(
        "Skipping fetchLastInspection: yardId or templateId is missing"
      );
      return;
    }

    try {
      console.log(
        `Fetching last inspection for yard ${yardId} and template ${templateId}`
      );
      let { data, error } = await supabase
        .from("inspections")
        .select("date, current_visit_rating")
        .eq("yard_id", yardId)
        .eq("template_id", templateId)
        .order("created_at", { ascending: false })
        .limit(1);

      if (error) throw error;

      if (data && data.length > 0) {
        console.log("Last inspection data found:", data[0]);
        setBaseData((prevData) => ({
          ...prevData,
          lastVisitDate: data[0].date,
          lastVisitRating: data[0].current_visit_rating,
        }));
      } else {
        console.log("No previous inspection found");
        setBaseData((prevData) => ({
          ...prevData,
          lastVisitDate: "",
          lastVisitRating: "",
        }));
      }
    } catch (error) {
      console.error("Failed to fetch last inspection:", error);
      setError("Failed to fetch last inspection data: " + error.message);
    }
  };

  const fetchSections = async (templateId) => {
    try {
      let { data, error } = await supabase
        .from("sections")
        .select(
          "id, title, questions(id, text, type, allow_photo, allow_comments)"
        )
        .eq("template_id", templateId);

      if (error) throw error;
      setSections(data);
    } catch (error) {
      setError("Failed to fetch sections and questions.");
      console.error(error);
    }
  };

  const startInspection = (templateId) => {
    setError(""); // Clear any previous errors
    setSelectedTemplate(templateId);
    setBaseData((prevData) => ({
      ...prevData,
      date: new Date().toISOString().split("T")[0],
      lastVisitDate: "",
      lastVisitRating: "",
      template_id: templateId,
    }));
    fetchSections(templateId);
  };

  const handleBaseDataChange = (e) => {
    const { name, value } = e.target;
    setBaseData({ ...baseData, [name]: value });

    if (name === "yard_id" && value) {
      fetchLastInspection(value);
    }
  };

  const calculateRating = (currentResponses) => {
    console.log("Current responses:", currentResponses);

    const scores = Object.entries(currentResponses)
      .map(([questionId, response]) => {
        let score;
        switch (response.response) {
          case "Below Expectations":
            score = 1;
            break;
          case "Meets Expectations":
            score = 2;
            break;
          case "Exceeds Expectations":
            score = 3;
            break;
          default:
            score = null;
        }
        console.log(
          `Question ${questionId}: ${response.response} - Score: ${score}`
        );
        return score;
      })
      .filter((score) => score !== null);

    console.log("Filtered scores:", scores);

    if (scores.length === 0) {
      console.log("No valid scores, returning 0");
      return 0;
    }

    const sum = scores.reduce((sum, score) => sum + score, 0);
    console.log("Sum of scores:", sum);
    console.log("Number of scores:", scores.length);

    const averageScore = sum / scores.length;
    console.log("Average score:", averageScore);

    const roundedScore = parseFloat(averageScore.toFixed(2));
    console.log("Rounded score:", roundedScore);

    return roundedScore;
  };

  const getRatingText = (rating) => {
    if (rating < 2) {
      return "Below Expectations";
    } else if (rating >= 2 && rating <= 2.75) {
      return "Meets Expectations";
    } else {
      return "Exceeds Expectations";
    }
  };

  const handleResponseChange = (sectionId, questionId, value) => {
    console.log(`Changing response for question ${questionId} to ${value}`);

    setResponses((prevResponses) => {
      const updatedResponses = {
        ...prevResponses,
        [questionId]: {
          ...prevResponses[questionId],
          response: value,
          section_id: sectionId,
        },
      };
      console.log("Updated responses:", updatedResponses);

      const newRating = calculateRating(updatedResponses);
      console.log("New calculated rating:", newRating);

      const newRatingText = getRatingText(newRating);
      console.log("New rating text:", newRatingText);

      setCurrentRating(newRating);
      console.log("Setting currentRating to:", newRating);

      setBaseData((prevBaseData) => {
        const newBaseData = {
          ...prevBaseData,
          currentVisitRating: newRatingText,
        };
        console.log("New baseData:", newBaseData);
        return newBaseData;
      });

      return updatedResponses;
    });
  };

  const handlePhotoChange = (sectionId, questionId, file) => {
    setResponses({
      ...responses,
      [questionId]: {
        ...responses[questionId],
        image: file,
        section_id: sectionId,
      },
    });
  };

  const handleCommentChange = (sectionId, questionId, comment) => {
    setResponses({
      ...responses,
      [questionId]: {
        ...responses[questionId],
        comment: comment,
        section_id: sectionId,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!baseData.date || !baseData.yard_id || !baseData.gm_id) {
      setError("Please fill out all required fields (date, yard, GM).");
      return;
    }

    if (!selectedTemplate) {
      setError("Please select a template.");
      return;
    }

    try {
      const inspectionData = {
        template_id: selectedTemplate,
        user_id: userId,
        date: baseData.date,
        yard_id: baseData.yard_id,
        gm_id: baseData.gm_id,
        last_visit_date: baseData.lastVisitDate || null,
        last_visit_rating: baseData.lastVisitRating || null,
        current_visit_rating: baseData.currentVisitRating || null,
      };

      const { data: inspection, error: inspectionError } = await supabase
        .from("inspections")
        .insert([inspectionData])
        .select();

      if (inspectionError) throw inspectionError;

      const inspectionId = inspection[0].id;

      for (const questionId in responses) {
        const { response, image, comment, section_id } = responses[questionId];
        let imageUrl = null;

        if (image) {
          const filePath = `${inspectionId}/${questionId}`;
          console.log("Attempting to upload file:", filePath);

          const { data: uploadData, error: uploadError } =
            await supabase.storage
              .from("inspection-images")
              .upload(filePath, image);

          if (uploadError) {
            console.error("Upload error:", uploadError);
            throw uploadError;
          }

          console.log("File uploaded successfully:", uploadData);

          const { data: signedUrlData, error: signedUrlError } =
            await supabase.storage
              .from("inspection-images")
              .createSignedUrl(filePath, 60 * 60 * 24 * 365 * 10);

          if (signedUrlError) {
            console.error("Signed URL generation error:", signedUrlError);
            throw signedUrlError;
          }

          imageUrl = signedUrlData.signedUrl;
          console.log("Generated signed URL:", imageUrl);
        }

        const responseData = {
          inspection_id: inspectionId,
          question_id: questionId,
          response,
          image_url: imageUrl,
          comment,
          section_id,
          user_id: userId,
        };

        const { error: responseError } = await supabase
          .from("responses")
          .insert([responseData]);

        if (responseError) throw responseError;
      }

      setSuccess(true);
    } catch (error) {
      console.error("Failed to submit inspection:", error);
      setError(`Failed to submit inspection: ${error.message}`);
    }
  };

  const handleCloseSuccessModal = () => {
    setSuccess(false);
    window.location.reload(); // Reload the page after the modal closes
  };

  console.log("Render: currentRating =", currentRating);
  console.log(
    "Render: baseData.currentVisitRating =",
    baseData.currentVisitRating
  );

  return (
    <div className="inspection-container">
      {!selectedTemplate && (
        <div>
          <h2>Available Inspections</h2>
          <div className="template-list">
            {templates.map((template) => (
              <div key={template.id} className="template-item">
                <span>{template.name}</span>
                <button
                  onClick={() => startInspection(template.id)}
                  className="btn-primary"
                >
                  Start Inspection
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      {selectedTemplate && (
        <div className="form-container">
          <h3>Inspection Information</h3>
          <div className="form-section">
            {/* ... other form fields ... */}
            <label>Inspection:</label>
            <input
              className="input-field"
              type="text"
              value={
                templates.find((t) => t.id === baseData.template_id)?.name || ""
              }
              readOnly
            />
            {/* ... rest of the form ... */}
          </div>
        </div>
      )}

      {selectedTemplate && (
        <div className="form-container">
          <h3>Inspection Information</h3>
          <div className="form-section">
            <label>Date:</label>
            <input
              className="input-field"
              type="date"
              name="date"
              value={baseData.date}
              onChange={handleBaseDataChange}
              required
            />
            <label>Yard:</label>
            <select
              className="input-field"
              name="yard_id"
              value={baseData.yard_id}
              onChange={handleBaseDataChange}
              required
            >
              <option value="">Select Yard</option>
              {yards.map((yard) => (
                <option key={yard.id} value={yard.id}>
                  {yard.name}
                </option>
              ))}
            </select>
            <label>GM:</label>
            <select
              className="input-field"
              name="gm_id"
              value={baseData.gm_id}
              onChange={handleBaseDataChange}
              required
            >
              <option value="">Select GM</option>
              {gms.map((gm) => (
                <option key={gm.id} value={gm.id}>
                  {gm.name}
                </option>
              ))}
            </select>
            <label>Last Visit Date:</label>
            <input
              className="input-field"
              type="date"
              name="lastVisitDate"
              value={baseData.lastVisitDate}
              onChange={handleBaseDataChange}
              readOnly
            />
            <label>Last Visit Rating:</label>
            <input
              className="input-field"
              type="text"
              name="lastVisitRating"
              value={baseData.lastVisitRating}
              onChange={handleBaseDataChange}
              readOnly
            />
            <label>Current Visit Rating:</label>
            <input
              className="input-field"
              type="text"
              name="currentVisitRating"
              value={baseData.currentVisitRating}
              readOnly
            />
          </div>

          {sections.length > 0 && (
            <form onSubmit={handleSubmit}>
              {sections.map((section) => (
                <div key={section.id} className="form-section">
                  <h3>{section.title}</h3>
                  {section.questions.map((question) => (
                    <div key={question.id} className="question-container">
                      <label>{question.text}</label>
                      <select
                        className="input-field"
                        onChange={(e) =>
                          handleResponseChange(
                            section.id,
                            question.id,
                            e.target.value
                          )
                        }
                        required
                      >
                        <option value="">Select a response</option>
                        <option value="Below Expectations">
                          Below Expectations
                        </option>
                        <option value="Meets Expectations">
                          Meets Expectations
                        </option>
                        <option value="Exceeds Expectations">
                          Exceeds Expectations
                        </option>
                      </select>

                      <span>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            openModal(question.description);
                          }}
                          className="instructions-link"
                        >
                          Instructions
                        </a>
                      </span>

                      {question.allow_photo && (
                        <input
                          className="input-field"
                          type="file"
                          accept="image/*"
                          capture="environment"
                          onChange={(e) =>
                            handlePhotoChange(
                              section.id,
                              question.id,
                              e.target.files[0]
                            )
                          }
                        />
                      )}
                      {question.allow_comments && (
                        <textarea
                          className="input-field"
                          placeholder="Add a comment..."
                          onChange={(e) =>
                            handleCommentChange(
                              section.id,
                              question.id,
                              e.target.value
                            )
                          }
                        />
                      )}
                    </div>
                  ))}
                </div>
              ))}
              <button type="submit" className="btn-submit">
                Submit Inspection
              </button>
            </form>
          )}
        </div>
      )}

      {error && <p className="error">{error}</p>}

      {success && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Success!</h2>
            <p style={{ marginBottom: "20px" }}>
              Inspection submitted successfully.
            </p>
            <button onClick={handleCloseSuccessModal} className="btn-primary">
              OK
            </button>
          </div>
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Instructions"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Instructions</h2>
        <p>{modalContent}</p>
        <button onClick={closeModal} className="btn-primary">
          Close
        </button>
      </Modal>
    </div>
  );
};

export default NewInspection;
