import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import '../styles/CreateTemplate.css';

const CreateTemplate = () => {
  const [templateName, setTemplateName] = useState('');
  const [description, setDescription] = useState('');
  const [sections, setSections] = useState([
    {
      title: '',
      questions: [
        {
          text: '',
          type: 'text',
          allow_photo: false,
          allow_comments: false,
          photo_required: false,
          description: '', // Add the description field for each question
        },
      ],
    },
  ]);
  const [templates, setTemplates] = useState([]);
  const [editingTemplateId, setEditingTemplateId] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const { data, error } = await supabase.from('inspection_templates').select('*');
      if (error) throw error;
      setTemplates(data);
    } catch (error) {
      setError('Failed to fetch templates: ' + error.message);
      console.error(error);
    }
  };

  const handleSectionChange = (index, value) => {
    const newSections = [...sections];
    newSections[index].title = value;
    setSections(newSections);
  };

  const handleQuestionChange = (sectionIndex, questionIndex, field, value) => {
    const newSections = [...sections];
    newSections[sectionIndex].questions[questionIndex][field] = value;
    setSections(newSections);
  };

  const addSection = () => {
    setSections([
      ...sections,
      {
        title: '',
        questions: [
          {
            text: '',
            type: 'text',
            allow_photo: false,
            allow_comments: false,
            photo_required: false,
            description: '', // Add the description field for new questions
          },
        ],
      },
    ]);
  };

  const addQuestion = (sectionIndex) => {
    const newSections = [...sections];
    newSections[sectionIndex].questions.push({
      text: '',
      type: 'text',
      allow_photo: false,
      allow_comments: false,
      photo_required: false,
      description: '', // Add the description field for new questions
    });
    setSections(newSections);
  };

  const removeSection = (sectionIndex) => {
    const newSections = sections.filter((_, index) => index !== sectionIndex);
    setSections(newSections);
  };

  const removeQuestion = (sectionIndex, questionIndex) => {
    const newSections = [...sections];
    newSections[sectionIndex].questions = newSections[sectionIndex].questions.filter(
      (_, index) => index !== questionIndex
    );
    setSections(newSections);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    try {
      if (editingTemplateId) {
        // Update existing template
        const { error: templateError } = await supabase
          .from('inspection_templates')
          .update({ name: templateName, description })
          .eq('id', editingTemplateId);

        if (templateError) throw templateError;

        const { data: existingSections, error: fetchError } = await supabase
          .from('sections')
          .select('id, title')
          .eq('template_id', editingTemplateId);

        if (fetchError) throw fetchError;

        for (const section of sections) {
          const existingSection = existingSections.find(s => s.title === section.title);
          if (existingSection) {
            const { error: sectionError } = await supabase
              .from('sections')
              .update({ title: section.title })
              .eq('id', existingSection.id);

            if (sectionError) throw sectionError;
            await handleQuestions(existingSection.id, section.questions);
          } else {
            const { data: newSection, error: insertError } = await supabase
              .from('sections')
              .insert({ template_id: editingTemplateId, title: section.title })
              .select();

            if (insertError) throw insertError;
            await handleQuestions(newSection[0].id, section.questions);
          }
        }

        const sectionsToDelete = existingSections.filter(
          existingSection => !sections.some(s => s.title === existingSection.title)
        );

        for (const sectionToDelete of sectionsToDelete) {
          const { error: deleteError } = await supabase
            .from('sections')
            .delete()
            .eq('id', sectionToDelete.id);

          if (deleteError) throw deleteError;
        }

        setSuccess('Template updated successfully!');
      } else {
        const { data: template, error: templateError } = await supabase
          .from('inspection_templates')
          .insert([{ name: templateName, description }])
          .select();

        if (templateError || !template || template.length === 0) {
          throw templateError;
        }

        const templateId = template[0].id;

        for (const section of sections) {
          const { data: sectionData, error: sectionError } = await supabase
            .from('sections')
            .insert([{ template_id: templateId, title: section.title }])
            .select();

          if (sectionError || !sectionData || sectionData.length === 0) {
            console.error('Error inserting section or no section data returned:', sectionError);
            continue;
          }

          const sectionId = sectionData[0].id;
          await handleQuestions(sectionId, section.questions);
        }
        setSuccess('Template created successfully!');
      }

      resetForm();
      fetchTemplates();
    } catch (error) {
      setError('Failed to submit template: ' + error.message);
      console.error(error);
    }
  };

  const handleQuestions = async (sectionId, questions) => {
    const { data: existingQuestions, error: fetchError } = await supabase
      .from('questions')
      .select('id, text')
      .eq('section_id', sectionId);

    if (fetchError) throw fetchError;

    for (const question of questions) {
      const existingQuestion = existingQuestions.find(q => q.text === question.text);
      if (existingQuestion) {
        const { error: updateError } = await supabase
          .from('questions')
          .update({
            text: question.text,
            type: question.type,
            allow_photo: question.allow_photo,
            allow_comments: question.allow_comments,
            photo_required: question.photo_required,
            description: question.description, // Ensure the description field is saved
          })
          .eq('id', existingQuestion.id);

        if (updateError) throw updateError;
      } else {
        const { error: insertError } = await supabase
          .from('questions')
          .insert({
            section_id: sectionId,
            text: question.text,
            type: question.type,
            allow_photo: question.allow_photo,
            allow_comments: question.allow_comments,
            photo_required: question.photo_required,
            description: question.description, // Ensure the description field is saved
          });

        if (insertError) throw insertError;
      }
    }

    const questionsToDelete = existingQuestions.filter(
      existingQuestion => !questions.some(q => q.text === existingQuestion.text)
    );

    for (const questionToDelete of questionsToDelete) {
      const { error: deleteError } = await supabase
        .from('questions')
        .delete()
        .eq('id', questionToDelete.id);

      if (deleteError) throw deleteError;
    }
  };

  const resetForm = () => {
    setTemplateName('');
    setDescription('');
    setSections([
      {
        title: '',
        questions: [
          {
            text: '',
            type: 'text',
            allow_photo: false,
            allow_comments: false,
            photo_required: false,
            description: '', // Add the description field
          },
        ],
      },
    ]);
    setEditingTemplateId(null);
    setError('');
    setSuccess('');
  };

  const handleEdit = async (templateId) => {
    try {
      const { data: templateData, error } = await supabase
        .from('inspection_templates')
        .select(
          `
          id, name, description,
          sections!sections_template_id_fkey (
            id, title,
            questions!questions_section_id_fkey (
              id, text, type, allow_photo, allow_comments, photo_required, description
            )
          )
        `
        )
        .eq('id', templateId)
        .single();

      if (error || !templateData) throw error;

      const sectionsData = templateData.sections || [];
      const formattedSections = sectionsData.map((section) => ({
        title: section.title,
        questions: (section.questions || []).map((q) => ({
          text: q.text,
          type: q.type,
          allow_photo: q.allow_photo,
          allow_comments: q.allow_comments,
          photo_required: q.photo_required,
          description: q.description, // Make sure description is populated when editing
        })),
      }));

      setTemplateName(templateData.name);
      setDescription(templateData.description);
      setSections(formattedSections);
      setEditingTemplateId(templateId);
    } catch (error) {
      setError('Failed to load template for editing: ' + error.message);
      console.error(error);
    }
  };

  return (
    <div className="page-container">
      <div className="existing-templates-container">
        <h2 className="main-heading">Existing Template Listing</h2>
        <ul className="template-list">
          {templates.map((template) => (
            <li key={template.id} className="template-item">
              {template.name}
              <button className="btn-secondary" onClick={() => handleEdit(template.id)}>
                Edit
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="create-template-container">
        <h2 className="main-heading">
          {editingTemplateId ? 'Edit Template' : 'Create New Template'}
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="input-field">
            <label>
              Template Name:
              <input
                type="text"
                className="input-field"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
                required
              />
            </label>
          </div>

          <div className="input-field">
            <label>
              Description:
              <textarea
                className="input-field"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </label>
          </div>

          {sections.map((section, sectionIndex) => (
            <div key={sectionIndex} className="section-container">
              <div className="section-header">
                <h3>Section {sectionIndex + 1}</h3>
                <button
                  type="button"
                  className="btn-danger"
                  onClick={() => removeSection(sectionIndex)}
                >
                  Remove Section
                </button>
              </div>
              <input
                type="text"
                className="input-field"
                placeholder="Section Title"
                value={section.title}
                onChange={(e) => handleSectionChange(sectionIndex, e.target.value)}
                required
              />
              {section.questions.map((question, questionIndex) => (
                <div key={questionIndex} className="question-container">
                  <div className="question-header">
                    <h4>Question {questionIndex + 1}</h4>
                    <button
                      type="button"
                      className="btn-danger"
                      onClick={() => removeQuestion(sectionIndex, questionIndex)}
                    >
                      Remove Question
                    </button>
                  </div>
                  <input
                    type="text"
                    className="input-field"
                    placeholder="Question Text"
                    value={question.text}
                    onChange={(e) =>
                      handleQuestionChange(sectionIndex, questionIndex, 'text', e.target.value)
                    }
                    required
                  />
                  <textarea
                    className="input-field"
                    placeholder="Question Description (Hover Text)"
                    value={question.description} // Add input for question description
                    onChange={(e) =>
                      handleQuestionChange(sectionIndex, questionIndex, 'description', e.target.value)
                    }
                  />
                  <select
                    className="input-field"
                    value={question.type}
                    onChange={(e) =>
                      handleQuestionChange(sectionIndex, questionIndex, 'type', e.target.value)
                    }
                  >
                    <option value="text">Text</option>
                    <option value="multiple_choice">Multiple Choice</option>
                    <option value="rating">Rating</option>
                    <option value="image">Image</option>
                  </select>
                  <label>
                    Allow Photo:
                    <input
                      type="checkbox"
                      checked={question.allow_photo}
                      onChange={(e) =>
                        handleQuestionChange(
                          sectionIndex,
                          questionIndex,
                          'allow_photo',
                          e.target.checked
                        )
                      }
                    />
                  </label>
                  <label>
                    Allow Comments:
                    <input
                      type="checkbox"
                      checked={question.allow_comments}
                      onChange={(e) =>
                        handleQuestionChange(
                          sectionIndex,
                          questionIndex,
                          'allow_comments',
                          e.target.checked
                        )
                      }
                    />
                  </label>
                  <label>
                    Photo Required:
                    <input
                      type="checkbox"
                      checked={question.photo_required}
                      onChange={(e) =>
                        handleQuestionChange(
                          sectionIndex,
                          questionIndex,
                          'photo_required',
                          e.target.checked
                        )
                      }
                    />
                  </label>
                  <div className="question-divider"></div>
                </div>
              ))}
              <button
                type="button"
                className="btn-primary"
                onClick={() => addQuestion(sectionIndex)}
              >
                Add Question
              </button>
            </div>
          ))}

          <button type="button" className="btn-primary" onClick={addSection}>
            Add Section
          </button>
          <div className="button-group">
            <button type="submit" className="btn-submit">
              {editingTemplateId ? 'Save' : 'Create Template'}
            </button>
            <button type="button" className="btn-cancel" onClick={resetForm}>
              Cancel
            </button>
          </div>
        </form>

        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
      </div>
    </div>
  );
};

export default CreateTemplate;